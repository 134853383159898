import React from 'react';
import './SlideOver.scss';
export interface SlideOverData {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  width?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl';
  header: string;
  subHeader?: string;
}
interface SlideOverProps {
  children: React.ReactNode;
  slideOverData: SlideOverData;
  type?: string;
}

const SlideOver: React.FC<SlideOverProps> = ({
  children,
  slideOverData: { isOpen, onClose, width = 'l', header, subHeader, id },
  type,
}) => {
  const getWidth = () => {
    switch (width) {
      case 's':
        return '300px';
      case 'm':
        return '500px';
      case 'l':
        return '600px';
      case 'xl':
        return '800px';
      case '2xl':
        return '1000px';
      case '3xl':
        return '1300px';
      default:
        return '300px';
    }
  };

  return (
    <div className={`slideover ${isOpen ? 'open' : ''}`} key={id}>
      <div className="slideover__overlay" onClick={onClose}></div>
      <div className="slideover__content" style={{ width: getWidth() }}>
        <div className={`slideover__header ${type === 'notes' ? 'slideover__header__yellow' : ''}`}>
          <i
            className={`ni ni-bold-left ${
              type === 'notes' ? 'slideover__header_icon__black' : 'slideover__header_icon'
            }`}
            onClick={onClose}
          />
          {subHeader ? (
            <div
              className={type === 'notes' ? 'slideover__header__black' : 'slideover__header__white'}
            >
              <div className="slideover__header__subtext">{header}</div>
              <div className="slideover__header__text">{subHeader}</div>
            </div>
          ) : (
            <div
              className={type === 'notes' ? 'slideover__header__black' : 'slideover__header__white'}
            >
              <div className="slideover__header__text">{header}</div>
            </div>
          )}
        </div>
        <div className="slideover__child">{children}</div>
      </div>
    </div>
  );
};

export default SlideOver;
