import type { Resource } from '@app/@types/redux/project';
import { Row, Col, CardImg, CardBody, CardTitle, CardText } from '@components/UI/Html';
import { type SlideOverData } from '@components/UI/Html/SlideOver';
import { i18nCText } from '@libs/i18n/I18n';
import { Card, Button } from 'reactstrap';
import type { Dispatch, SetStateAction } from 'react';
import resourcePng from '../../assets/images/placeholders/resource.png';

interface ResourceSlideOverProps {
  slideOverData: SlideOverData;
  setSlideOverData: Dispatch<SetStateAction<SlideOverData>>;
  resources: boolean;
  challengeResources: Resource[];
  workspaceResources: Resource[];
}
const Resources = ({
  resources,
  challengeResources,
  workspaceResources,
}: ResourceSlideOverProps) => {
  return (
    <div>
      {resources ? (
        <div>
          <>
            <h2 className="mb-4">Challenge Resources</h2>
            <Row>
              {challengeResources.length > 0 ? (
                challengeResources.map((resource) => {
                  return (
                    <Col key={resource.url} className="" sm={6} md={4}>
                      <Card>
                        <CardImg alt="..." src={resourcePng} top />
                        <CardBody>
                          <CardTitle className="font-weight-bold">
                            {i18nCText(resource.name)}
                          </CardTitle>
                          <CardText>
                            <Button
                              color="link"
                              onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                window.open(resource.url);
                              }}
                            >
                              View
                            </Button>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <Col className="text-center" sm={12} md={12}>
                  No resources found
                </Col>
              )}
            </Row>
            <h2 className="my-4">Workspace Resources</h2>
            <Row>
              {workspaceResources.length > 0 ? (
                workspaceResources.map((resource) => {
                  return (
                    <Col key={resource.url} className="" sm={6} md={4}>
                      <Card>
                        <CardImg alt="..." src={resourcePng} top />
                        <CardBody>
                          <CardTitle className="font-weight-bold">
                            {i18nCText(resource.name)}
                          </CardTitle>
                          <CardText>
                            <Button
                              color="link"
                              onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                window.open(resource.url);
                              }}
                            >
                              Download
                            </Button>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <Col className="text-center" sm={12} md={12}>
                  No resources found
                </Col>
              )}
            </Row>
          </>
        </div>
      ) : (
        <div>
          <p>No resources available</p>
        </div>
      )}
    </div>
  );
};
export default Resources;
