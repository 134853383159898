import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { Evaluation, UserEvaluation, UserProject } from '../../../@types/redux/project';
import {
  Badge,
  Country,
  Degree,
  Note,
  Notification,
  UpdateUserData,
  User,
  UserEducation,
  UserExperience,
  UserPreferences,
  UserSoftSkill,
} from '../../../@types/redux/users';
import Api from '../../utils/Api';
import { fetchMe } from './auth.slice';

interface UserReducer {
  countries: Country[];
  notifications: Notification[];
  preferences: UserPreferences;
  degree: Degree;
  user: User;
  notes: {
    currentNote: Note;
    myNotes: Note[];
  };
  badges: Badge[];
  skills: UserSoftSkill[];
  projects: UserProject[];
  totalPages: number;
  evaluations: Evaluation[];
  myEvaluations?: UserEvaluation[];
  growths: number[];
}

const initialState: UserReducer = {
  countries: [],
  notifications: [],
  growths: [],
  preferences: {
    showWelcomeModal: false,
    showOnboarding: false,
    showLanguageSelect: false,
    language: 'en',
    personalizedJobFinder: false,
    recommendedCourses: false,
    welcomeMailSent: false,
    changePassword: false,
  },
  degree: {
    id: '',
    type: '',
    attributes: {
      name: '',
    },
  },
  user: {
    id: '',
    type: '',
    attributes: {
      email: '',
      firstName: '',
      lastName: '',
      gender: '',
      state: '',
      city: '',
      country: '',
      bio: '',
      slug: '',
      birthdate: '',
      preferences: {
        showWelcomeModal: false,
        showOnboarding: false,
        showLanguageSelect: false,
        language: 'en',
        personalizedJobFinder: false,
        recommendedCourses: false,
        welcomeMailSent: false,
        changePassword: false,
      },
      tedoPoints: 0,
      confirmedAt: '',
      confirmationSentAt: '',
      userEducation: {
        school: '',
        degree: '',
        major: '',
      },
      userExperience: {
        company: '',
        employmentStatus: '',
        experienceLevel: '',
        occupation: '',
        industry: '',
      },
      phoneNumber: '',
      nationality: '',
      userRoles: [],
      socialMediaLinks: [],
      about: '',
      name: '',
      courses: '',
      hobbies: '',
      avatarUrl: '',
      amountComments: 0,
      amountProjects: 0,
      amountIdeas: 0,
    },
  },
  notes: {
    currentNote: {
      id: '',
      type: '',
      attributes: {
        id: '',
        title: '',
        text: '',
        updatedAt: '',
      },
    },
    myNotes: [],
  },
  badges: [],
  skills: [],
  projects: [],
  totalPages: 0,
  evaluations: [],
  myEvaluations: [],
};

export const updateNote = createAsyncThunk(
  'user/updateNote',
  async ({ data, saveBtnFn }: { data: Note['attributes']; saveBtnFn: () => void }) => {
    const response = await Api.updateNote(data);
    saveBtnFn();
    return response.data;
  }
);

export const updateNotifications = createAsyncThunk(
  'user/updateNotifications',
  async (notificationIds: string[]) => {
    const response = await Api.updateNotifications({ notificationIds });
    return response.data.data;
  }
);

export const fetchNotifications = createAsyncThunk('user/fetchNotifications', async () => {
  const response = await Api.getNotifications();
  return response.data.data;
});

export const fetchCountries = createAsyncThunk('user/fetchCountries', async () => {
  const response = await Api.getCountries();
  return response.data;
});

export const fetchPreferences = createAsyncThunk('user/fetchPreferences', async () => {
  const response = await Api.getPreferences();
  return response.data;
});

export const fetchDegree = createAsyncThunk('user/fetchDegree', async (id: string) => {
  const response = await Api.getDegree({ id });
  return response.data;
});

export const updatePreferences = createAsyncThunk(
  'user/updatePreferences',
  async (data: UserPreferences) => {
    const response = await Api.updatePreferences({ preferences: data });
    return response.data;
  }
);

export const fetchNote = createAsyncThunk('user/fetchNote', async (id: string) => {
  const response = await Api.getNote(id);
  return response.data.data;
});

export const fetchMyNotes = createAsyncThunk('user/fetchMyNotes', async () => {
  const response = await Api.getNotes();
  return response.data;
});

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  async ({
    password,
    passwordConfirmation,
    currentPassword,
  }: {
    password: string;
    passwordConfirmation: string;
    currentPassword: string;
  }) => {
    const response = await Api.updatePassword(password, passwordConfirmation, currentPassword);
    return response.data;
  }
);

export const fetchUserMyBadges = createAsyncThunk('user/fetchUserMyBadges', async () => {
  const response = await Api.getMyBadges();
  return response.data;
});

export const fetchUserMySkills = createAsyncThunk('user/fetchUserMySkills', async () => {
  const response = await Api.getMySkills();
  return response.data.data;
});

export const fetchUserMyProjects = createAsyncThunk(
  'user/fetchUserMyProjects',
  async ({
    currentPage,
    sortOption,
    sortDirection,
  }: {
    currentPage: number;
    sortOption: string;
    sortDirection: string;
  }) => {
    const response = await Api.getMyProjects(currentPage, 20, sortDirection, sortOption, '');
    return response.data;
  }
);

export const fetchEvaluations = createAsyncThunk('user/fetchEvaluations', async () => {
  const response = await Api.getEvaluations();
  return response.data;
});

export const updateAccountSettings = createAsyncThunk(
  'user/updateAccountSettings',
  async (data: UpdateUserData) => {
    const response = await Api.updateAccountSettings(data);
    return response.data.data;
  }
);

export const updateExperienceSettings = createAsyncThunk(
  'user/updateExperienceSettings',
  async ({ experience, education }: { experience: UserExperience; education: UserEducation }) => {
    const response = await Api.updateExperienceSettings(experience, education);
    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
    builder.addCase(updateNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
    builder.addCase(fetchPreferences.fulfilled, (state, action) => {
      state.preferences = action.payload;
    });
    builder.addCase(fetchDegree.fulfilled, (state, action) => {
      state.degree = action.payload;
    });
    builder.addCase(updatePreferences.fulfilled, (state, action) => {
      state.preferences = action.payload;
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      state.notes.currentNote = action.payload;
    });
    builder.addCase(fetchNote.fulfilled, (state, action) => {
      state.notes.currentNote = action.payload;
    });
    builder.addCase(fetchMyNotes.fulfilled, (state, action) => {
      state.notes.myNotes = action.payload;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchUserMyBadges.fulfilled, (state, action) => {
      state.badges = action.payload;
    });
    builder.addCase(fetchUserMySkills.fulfilled, (state, action) => {
      state.skills = action.payload;
    });
    builder.addCase(fetchUserMyProjects.fulfilled, (state, action) => {
      state.projects = action.payload.data;
      state.totalPages = action.payload.meta.total;
    });
    builder.addCase(fetchEvaluations.fulfilled, (state, action) => {
      state.evaluations = action.payload.evaluations.data;
      state.myEvaluations = action.payload.myEvaluations.data;
    });
    builder.addCase(updateAccountSettings.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchMe.rejected, (state) => {
      state.user = initialState.user;
    });
  },
});

export default userSlice.reducer;
