import { useAppSelector } from '@state/redux/store';
import { getFeedback } from '@state/selectors/projects';
import './TedoFeedback.scss';
import type { PageElement, UserAnswerFeedback } from '@app/@types/redux/project';
import type { CardReviewAnswer } from '@app/@types/redux/answer';
import FeedbackState from './FeedbackState';
import type { CardFeedbackOptions } from '@app/@types/redux/pageElement';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { UncontrolledTooltip } from '@components/UI/Html';
import type { SlideOverData } from '@components/UI/Html/SlideOver';
import { PAGE_ELEMENT } from '@libs/constants/constants';

interface TedoFeedbackProps {
  slideOverData: SlideOverData;
  setSlideOverData: Dispatch<SetStateAction<SlideOverData>>;
  feedbackId?: string;
}
const TedoFeedback = ({ slideOverData, setSlideOverData, feedbackId }: TedoFeedbackProps) => {
  const feedbacks = useAppSelector((state) => getFeedback(state.projects));
  const project = useAppSelector((state) => state.projects.currentProject);

  const filteredFeedbacks = project.project.attributes.projectStages.filter((stage) => {
    return stage.attributes.stageName.toLowerCase() === 'feedback';
  });
  const findFeedbackId = (data: PageElement[]) => {
    return data.find((item) => item.attributes.elementType === PAGE_ELEMENT.CARD_FEEDBACK);
  };
  const [selectedId, setSelectedId] = useState<string | undefined>(
    feedbackId ??
      (filteredFeedbacks[0]?.attributes?.pages[0]?.attributes?.pageElements[0]?.id || undefined)
  );
  const userAnswers: UserAnswerFeedback[] | undefined =
    selectedId && typeof feedbacks[selectedId] !== 'string' ? feedbacks[selectedId] : undefined;
  let aiRating: UserAnswerFeedback | undefined;
  const reviewerUserAnswers = userAnswers
    ?.map((x) => x.attributes.reviewerUserAnswer?.attributes.answer as CardReviewAnswer | undefined)
    .filter((x) => x?.text);
  try {
    aiRating = userAnswers
      ? [...userAnswers]
          .reverse()
          .find((item) => item.attributes.reviewerAnswerReview.reviewType === 'ai')
      : undefined;
  } catch (err) {
    aiRating = undefined;
  }

  const preCheckFailed =
    (aiRating?.attributes.reviewerUserAnswer?.attributes.answer as CardReviewAnswer | undefined)
      ?.preCheckFailed ?? false;

  const feedbackText = aiRating?.attributes.reviewerAnswerReview.feedback;

  const aiRatingFirst = reviewerUserAnswers?.length ? reviewerUserAnswers[0] : undefined;

  const feedbackOption = findFeedbackId(
    filteredFeedbacks[0].attributes.pages[0].attributes.pageElements
  );
  const options = feedbackOption?.attributes.options as CardFeedbackOptions;

  return (
    <div>
      {slideOverData.width === 'l' ? (
        <div>
          {filteredFeedbacks.map((feedback) => {
            const rawId = findFeedbackId(feedback.attributes.pages[0].attributes.pageElements);
            if (!rawId) return null;
            const id = `feedback-${rawId.id}`;
            const hasFeedback = feedbackId ?? rawId.id in feedbacks;

            return (
              <>
                <div
                  key={feedback.id}
                  className={hasFeedback ? 'tf-container' : 'tf-container tf-disabled'}
                  id={id}
                  onClick={() => {
                    if (hasFeedback) {
                      setSelectedId(rawId.id);
                      setSlideOverData({
                        ...slideOverData,
                        isOpen: true,
                        header: feedback.attributes.parentStageName,
                        subHeader: 'Tedo Feedback',
                        width: '3xl',
                      });
                    }

                    // setSubHeader(feedback.attributes.parentStageName);
                  }}
                >
                  <div className="tf-icon-container">
                    <i className={`ni ${feedback.attributes.icon} tf-icon-main`} />
                    {/* <img src={feedback.attributes.icon} alt="icon" className="tf-icon" /> */}
                  </div>
                  <h2 className="tf-text">{feedback.attributes.parentStageName}</h2>
                </div>
                {!hasFeedback && (
                  <UncontrolledTooltip delay={0} placement="bottom" target={id}>
                    No Feedback Available
                  </UncontrolledTooltip>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <FeedbackState
          competencyQuestions={options.competencyQuestions}
          skillQuestions={options.questions}
          preCheckFailed={preCheckFailed}
          feedbackText={feedbackText}
          aiRatingFirst={aiRatingFirst}
        />
      )}
    </div>
  );
};
export default TedoFeedback;
