import React from 'react';
import './NotificationModal.scss';
import { Button, Modal } from '@components/UI/Html';
import type { IconType } from 'react-icons/lib';

interface NotificationModalProps {
  isOpen: boolean;
  toggle: () => void;
  onViewMessage: () => void;
  icon?: string | IconType;
  imageSrc?: string;
  title: string;
  message: string;
  buttonIcon?: string;
  buttonText?: string;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  isOpen,
  toggle,
  onViewMessage,
  icon,
  title,
  message,
  buttonIcon,
  buttonText,
  imageSrc,
}) => {
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      className="custom__modal__gray"
      showCloseButton={false}
    >
      <div className="nf-container">
        <div className="nf-icon-container">
          {imageSrc ? (
            <img className="nf-image-profile" src={imageSrc} alt="icon" />
          ) : typeof icon === 'string' ? (
            <img className="nf-image" src={icon} alt="icon" />
          ) : (
            <div className="nf-icon">{icon && React.createElement(icon)}</div>
          )}
          <div className="nf-badge">1</div>
        </div>

        <h2 className="text-white">{title}</h2>
        <p className="text-gray">{message}</p>
        {buttonIcon && buttonText ? (
          <div
            className="nf-button-container"
            onClick={() => {
              toggle();
              onViewMessage();
            }}
          >
            <div className="nf-button-icon-container">
              <img className="nf-button-icon" src={buttonIcon} alt="icon" />
            </div>
            <div className="nf-button-text">{buttonText}</div>
          </div>
        ) : (
          <Button
            onClick={() => {
              toggle();
              onViewMessage();
            }}
            className="btn btn-primary mt-4"
          >
            View Message
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default NotificationModal;
