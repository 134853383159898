import React, { useEffect, useState } from 'react';

import './Message.scss';
import { useAppSelector } from '@state/redux/store';
import './Scenario.scss';
const Message: React.FC<{ htmlContent: string }> = ({ htmlContent }) => {
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const [scenarioData, setScenarioData] = useState({
    simulationType: '',
    organizationName: '',
    aboutOrganization: '',
    role: '',
    summary: '',
    organizationChallenge: '',
    roleDescription: '',
    scopeOfWork: [] as string[],
    managerImgSrc: '',
    managerName: '',
    managerRole: '',
    managerMessage: '',
  });

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const getTextContent = (label: string) => {
      const elements = Array.from(doc.querySelectorAll('span'));
      const element = elements.find((el) => el.textContent?.includes(label));
      return element ? element.textContent?.replace(label, '').trim() : '';
    };
    const getScopeOfWork = () => {
      const scopeElement = doc.querySelector('ol');
      if (scopeElement) {
        return Array.from(scopeElement.querySelectorAll('li')).map((li) => li.textContent ?? '');
      }
      return [];
    };
    setScenarioData({
      simulationType: getTextContent('Simulation Type:') ?? '',
      organizationName: getTextContent('Organization Name:') ?? '',
      aboutOrganization: getTextContent('About the Organization:') ?? '',
      role: getTextContent('Role:') ?? '',
      summary: getTextContent('Scenario Summary:') ?? '',
      organizationChallenge: getTextContent('Organizational Challenge:') ?? '',
      roleDescription: getTextContent('Role Description:') ?? '',
      scopeOfWork: getScopeOfWork(),
      managerImgSrc: getTextContent('Reporting Manager Image:') ?? '',
      managerName: getTextContent('Reporting Manager Name:') ?? '',
      managerRole: getTextContent('Reporting Manager Role:') ?? '',
      managerMessage: getTextContent('Reporting Manager Message:') ?? '',
    });
  }, [htmlContent]);

  return (
    <div>
      <div className="sc-organization-container">
        <div style={{ marginBottom: '2rem' }}>
          <div className="sc-subheader">Organization Name</div>
          <div className="sc-header">{scenarioData.organizationName}</div>
        </div>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <img
            src={currentProject.project.attributes.imageData}
            style={{ borderRadius: '12px', width: '23rem', height: '15rem' }}
          />
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="sc-dot" />
              <div className="sc-subheader">{scenarioData.simulationType}</div>
            </div>

            <div className="sc-header">Scenario Summary</div>
            <p>{scenarioData.summary}</p>
            <div>
              <div className="sc-role">Learner Role</div>
              <p className="sc-role-text">{scenarioData.role}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="sc-subheader sc-top">Background</div>
      <div className="sc-header">About the Organization</div>
      <p className="sc-text">{scenarioData.aboutOrganization}</p>

      <div className="sc-subheader sc-top">PROBLEM</div>
      <div className="sc-header">Organizational Challenge </div>
      <p className="sc-text">{scenarioData.organizationChallenge}</p>

      <div className="sc-learner-container sc-top">
        <div className="sc-subheader">Learner's Role</div>
        <div className="sc-learner-header">{scenarioData.role}</div>
        <div className="sc-learner-text">{scenarioData.roleDescription}</div>
        <div className="sc-learner-text">SCOPE OF WORK</div>
        <ol className="sc-learner-text list-decimal">
          {scenarioData.scopeOfWork.map((scope, index) => (
            <li key={index}>{scope}</li>
          ))}
        </ol>
      </div>
      <div className="sc-organization-container">
        <div className="sc-subheader">REPORTING MANAGER </div>
        <div className="sc-manager-container">
          <img className="sc-manager-img" src={scenarioData.managerImgSrc} />
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div className="sc-manager-name">{scenarioData.managerName},</div>
              <div className="sc-subheader">{scenarioData.managerRole}</div>
            </div>
            <div className="sc-text">{scenarioData.managerMessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
