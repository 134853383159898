/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import type { PageElementProps } from '@components/Projects/TakeProject';
import { useEffect, useState, type FC, type HTMLAttributes } from 'react';
import _ from 'lodash';

import './ExecutiveSummary.scss';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import type { CanvasOptions, TextareaOptions } from '@app/@types/redux/pageElement';
import type { CanvasAnswer } from '@app/@types/redux/answer';
import { getDependeeValues } from '@state/selectors/projects';
import { store, useAppSelector } from '@state/redux/store';
import { DependeeValue } from '@app/@types/redux/project';

type SummaryHeadingBlockProps = HTMLAttributes<HTMLDivElement> & {
  projectName?: string;
  year?: string;
};
const SummaryHeadingBlock: FC<SummaryHeadingBlockProps> = ({ projectName, year }) => {
  return (
    <div className="headingBlock">
      <div>
        <h1 className="title">Executive Summary</h1>
        <p className="subtitle">{projectName ?? ''}</p>
      </div>
      <time className="year">{year ?? new Date().getFullYear()}</time>
    </div>
  );
};

const A4Page = ({ children }: { children: React.ReactNode }) => (
  <div
    className="summaryContainer"
    style={
      {
        // width: '794px', // A4 width in pixels
        // height: '1123px', // A4 height in pixels
        // margin: '20px auto',
        // border: '1px solid #ccc',
        // padding: '22mm',
        // boxSizing: 'border-box', // Includes padding in height/width calculations
        // overflow: 'hidden',
        // backgroundColor: '#fff',
      }
    }
  >
    {children}
  </div>
);

const DocumentViewer = ({ children }: { children: React.ReactNode }) => {
  const content = ReactDOMServer.renderToStaticMarkup(<>{children}</>);
  const [pages, setPages] = useState<string[]>([]);

  useEffect(() => {
    const splitContentToPages = () => {
      const pageHeight = 1123 - 80; // A4 height minus padding (20px top + 20px bottom)
      const pageWidth = 754; // Content width minus padding (794px - 2 * 20px padding)
      const tempDiv = document.createElement('div');

      // Temporary div for calculating content height
      tempDiv.style.width = `${pageWidth}px`;
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.whiteSpace = 'normal';
      tempDiv.style.lineHeight = '1.6';
      tempDiv.style.fontSize = '1rem';
      tempDiv.style.boxSizing = 'border-box';
      tempDiv.style.padding = '80px';
      tempDiv.style.border = '1px solid transparent'; // Simulate actual border effect
      tempDiv.innerHTML = content;

      document.body.appendChild(tempDiv);
      const pagesArray: string[] = [];
      let remainingContent = content; // Keep the original content intact

      // Function to handle page splitting
      while (remainingContent) {
        tempDiv.style.height = `${pageHeight}px`;

        if (tempDiv.scrollHeight <= pageHeight) {
          // If all remaining content fits on one page
          pagesArray.push(tempDiv.innerHTML);
          break;
        }

        let splitIndex = remainingContent.length;

        // Reduce splitIndex progressively to fit content within page height
        while (tempDiv.scrollHeight > pageHeight && splitIndex > 0) {
          splitIndex = Math.floor(splitIndex * 0.9);
          tempDiv.innerHTML = remainingContent.substring(0, splitIndex);

          // Ensure the last tag is not broken, if so adjust the split point
          const lastOpenTagIndex = tempDiv.innerHTML.lastIndexOf('<');
          const lastCloseTagIndex = tempDiv.innerHTML.lastIndexOf('>');
          if (lastOpenTagIndex > lastCloseTagIndex) {
            splitIndex = lastOpenTagIndex;
          }
        }

        // Ensure the split does not cut words
        if (splitIndex > 0 && remainingContent[splitIndex] !== ' ') {
          const nearestSpaceBeforeSplitIndex = remainingContent.lastIndexOf(' ', splitIndex);
          if (nearestSpaceBeforeSplitIndex !== -1) {
            splitIndex = nearestSpaceBeforeSplitIndex;
          }
        }

        // Split remaining content within the tag
        let lastSplitContent = remainingContent.substring(0, splitIndex).trim();

        // Ensure the split does not cut words from the second half
        if (remainingContent[splitIndex] !== ' ') {
          const nearestSpaceAfterSplitIndex = remainingContent.indexOf(' ', splitIndex);
          if (nearestSpaceAfterSplitIndex !== -1) {
            splitIndex = nearestSpaceAfterSplitIndex;
          }
        }

        // Check if the split content ends with a closing tag and open tag
        if (lastSplitContent.includes('<p>') && !lastSplitContent.includes('</p>')) {
          lastSplitContent = `${lastSplitContent}</p>`;
        }
        console.log(lastSplitContent, '******');

        // Save the current page's content
        pagesArray.push(lastSplitContent);

        // Update remaining content
        remainingContent = remainingContent.substring(splitIndex).trim();
        tempDiv.innerHTML = remainingContent;
      }

      document.body.removeChild(tempDiv);
      setPages(pagesArray);
    };

    splitContentToPages();
  }, [content]);

  return (
    <div>
      {pages.map((pageContent, index) => (
        <A4Page key={index}>
          <div dangerouslySetInnerHTML={{ __html: pageContent }} />
        </A4Page>
      ))}
    </div>
  );
};

export const SummaryView = ({
  elements,
  options,
}: {
  elements: CanvasAnswer;
  options: CanvasOptions;
}) => {
  const project = useAppSelector((store) => store.projects.currentProject);
  const { questions = [] } = options;
  const sortedCollection = _.sortBy(elements, (item) => {
    const options = item.pageElement.options as TextareaOptions;
    return questions.indexOf(options.label);
  });

  const pElements = sortedCollection.map((element, index) => {
    const textareaOptions = element.pageElement.options as TextareaOptions;
    return (
      <div className="summaryBlock" key={index}>
        <h3 className="summaryBlock__question">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          {options.questions[index]?.title ?? textareaOptions.label}
        </h3>
        <p className="summaryBlock__answer">
          {typeof element.answer === 'string' ? element.answer : ''}
        </p>
      </div>
    );
  });

  return (
    <>
      <DocumentViewer>
        <SummaryHeadingBlock projectName={project.project.attributes.name} />
        <div className="divider"></div>
        {pElements}
      </DocumentViewer>
    </>
  );
};

export interface ExternalPageElementProps extends PageElementProps {
  answers?: CanvasAnswer;
  preview?: boolean;
}
const ExecutiveSummary: FC<PageElementProps> = (props) => {
  const {
    answer,
    pageElement: {
      attributes: { dependees },
    },
    pageElement,
    currentAnswer,
  } = props;
  const options = pageElement.attributes.options as CanvasOptions;
  const elements = currentAnswer as CanvasAnswer;

  useEffect(() => {
    const dependentValues = getDependeeValues(
      dependees,
      [],
      store.getState().projects
    ) as DependeeValue[];

    const elements = dependentValues.map((dependentValueItem) => {
      return {
        answer: dependentValueItem.answer,
        pageElement: dependentValueItem.dependeePageElement,
      };
    }) as CanvasAnswer;
    answer(elements);
  }, []);

  return <SummaryView elements={elements} options={options} />;
};
export default ExecutiveSummary;
