import EmailMessenger from '@components/Email';
import logger from '@libs/log';
import { fetchSimulationEmails } from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { useEffect, useState } from 'react';
import './InboxPage.scss';
import NotificationModal from '../NotificationModal';
import inboxIcon from '../../../../../assets/icons/inbox.png';
import type { ChatActivityAnswer } from '@app/@types/redux/answer';
import type { PageElement } from '@app/@types/redux/project';
interface InboxPageProps {
  pageElement: PageElement;
  currentAnswer: ChatActivityAnswer | undefined;
  answer: (answer: ChatActivityAnswer) => void;
}

const InboxPage: React.FC<InboxPageProps> = (props) => {
  const { pageElement } = props;
  const { id } = pageElement;
  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);
  const dispatch = useAppDispatch();
  const [notificationModal, setNotificationModal] = useState(false);
  const userProjectEmails = useAppSelector((state) => state.projects.currentProject.emails);

  useEffect(() => {
    dispatch(fetchSimulationEmails({ projectId, pageElementId: id })).catch(() => {
      logger.error('Failed to fetch simulation emails');
    });
  }, []);

  useEffect(() => {
    setNotificationModal(true);
  }, []);

  const topicWizardData = useAppSelector(
    (state) =>
      state.projects.currentProject.project.attributes.options.creationOptions.topicWizardData
  );

  return (
    <>
      <EmailMessenger emails={userProjectEmails} />
      <NotificationModal
        isOpen={notificationModal}
        toggle={() => {
          setNotificationModal(false);
        }}
        icon={inboxIcon}
        title="New Message"
        message={`1 new message ${
          topicWizardData.workplaceScenario?.learnerRoleReportingManager.reportingManager.name
            ? `from ${
                topicWizardData.workplaceScenario.learnerRoleReportingManager.reportingManager
                  .name || ''
              }`
            : ''
        }`}
        onViewMessage={() => {
          setNotificationModal(false);
        }}
        buttonIcon="https://cartedo.s3.us-east-2.amazonaws.com/web/499a44c4e3f38aad9848.png"
        buttonText="Click to view email"
      />
    </>
  );
};

export default InboxPage;
