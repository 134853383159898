import { OthersOptions } from '@app/@types/redux/pageElement';
import { PageElementProps } from '..';
import InboxPage from './HelperComponents/InboxPage';
import ResourcesPage from './HelperComponents/ResourcesPage';
import type { ChatActivityAnswer } from '@app/@types/redux/answer';

const Others: React.FC<PageElementProps> = (props) => {
  const {
    pageElement,
    // answer,
    // currentAnswer,
  } = props;
  const type = (pageElement.attributes.options as OthersOptions).type;
  let content = <></>;
  // const {
  //   pageElement: {
  //     options: { negativePostits, positivePostits, postits, passionOption },
  //   },
  // } = props;
  // const [projectModal, setProjectModal] = useState(false);
  // const [exampleModal, setExampleModal] = useState(false);
  switch (type) {
    // case "postit-1":
    //   content = (
    //     <Row className="mt-5 cards-container">
    //       <Postit text={positivePostits[0]} color="red" />
    //       <Postit text={negativePostits[0]} color="green" />
    //     </Row>
    //   );
    //   break;
    // case "postit-2":
    //   content = (
    //     <Row className="mt-5 cards-container">
    //       {postits.map((postIt) => (
    //         <Postit key={postIt} text={i18nCText(postIt)} />
    //       ))}
    //     </Row>
    //   );
    //   break;
    // case "passions-tips-button": {
    //   const selectedOption = _.find(methods, {
    //     type: passionOption,
    //   });
    //   content = (
    //     <Row>
    //       <Col className="d-flex justify-content-end">
    //         <TipsForServiceMarketplace
    //           examples={selectedOption?.examples}
    //           content={selectedOption?.content}
    //           isOpen={projectModal}
    //           toggleOpen={() => {
    //             setProjectModal(false);
    //           }}
    //         />
    //         <Modal
    //           isOpen={exampleModal}
    //           toggle={() => {
    //             setExampleModal(false);
    //           }}
    //           unmountOnClose
    //           size="lg"
    //         >
    //           <div className="p-4">
    //             <div className="animated fadeIn">
    //               <div className="row">
    //                 <div className="col">
    //                   <h2 className="message__text message__bigHeading">
    //                     <span>{i18nCText("Danny's Example")}</span>
    //                   </h2>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="animated fadeIn">
    //               <div className="row">
    //                 <div className="col">
    //                   <h2 className="message__text message__subHeading intext-underline-nested">
    //                     <span
    //                       dangerouslySetInnerHTML={{
    //                         __html: i18nCText(
    //                           "I love to <span>cook</span>, this helps me <span>spend time with my kids</span>. I think my passion can also help <span>other food-loving parents to spend more time creating meals with their kids</span>, without <span>the hassle of searching for recipes they can both enjoy</span>.",
    //                         ),
    //                       }}
    //                     />
    //                   </h2>
    //                 </div>
    //               </div>
    //             </div>
    //             <Row className="mt-5">
    //               {(postits || []).map((postIt) => (
    //                 <Postit
    //                   md={6}
    //                   key={postIt}
    //                   text={i18nCText(postIt)}
    //                   style={{
    //                     flex: "auto",
    //                     maxWidth: "50%",
    //                   }}
    //                 />
    //               ))}
    //             </Row>
    //           </div>
    //         </Modal>
    //         <Button
    //           className="btn-icon mb-4 mx-1"
    //           type="button"
    //           size="sm"
    //           color="primary"
    //           onClick={() => {
    //             setExampleModal(true);
    //           }}
    //         >
    //           <span className="btn-inner--icon">
    //             <FaStickyNote outline />
    //           </span>
    //           <span className="btn-inner--text mx-2">
    //             {i18nCText("Danny's Example")}
    //           </span>
    //         </Button>
    //         <Button
    //           className="btn-icon mb-4 mx-1"
    //           type="button"
    //           size="sm"
    //           color="danger"
    //           onClick={() => {
    //             setProjectModal(true);
    //           }}
    //         >
    //           <span className="btn-inner--icon">
    //             <FaRegLightbulb outline />
    //           </span>
    //           <span className="btn-inner--text mx-2">{i18nCText("Tips")}</span>
    //         </Button>
    //       </Col>
    //     </Row>
    //   );
    //   break;
    // }
    // case "fill_example_1": {
    //   const {
    //     pageElement: {
    //       id,
    //       options: { value: fillText, keys },
    //     },
    //   } = props;
    //   const matches = [];
    //   replace(fillText, FILL_BLANK_REGEX, (match) => {
    //     matches.push(match);
    //     return null;
    //   });

    //   let i = 0;
    //   const output = replace(
    //     i18nCText(fillText),
    //     FILL_BLANK_REGEX,
    //     (matchT, index) => {
    //       const match = matches[i];
    //       i += 1;
    //       return (
    //         <span key={`${id}${match}`} className="mx-2">
    //           <MUISelect
    //             options={keys?.[match]?.options?.slice(1)}
    //             placeholder={keys?.[match]?.options?.[0]?.toLowerCase()}
    //           />
    //         </span>
    //       );
    //     },
    //   );
    //   content = (
    //     <Row className="fillTheBlank__text">
    //       <Col>{output}</Col>
    //     </Row>
    //   );
    //   break;
    // }
    // case "job_recommendations":
    // case "job_recommendations_masters": {
    //   content = (
    //     <JobRecommendations
    //       currentAnswer={currentAnswer}
    //       answer={answer}
    //       pageElement={props.pageElement}
    //     />
    //   );
    //   break;
    // }
    case 'inbox':
      content = (
        <InboxPage
          pageElement={props.pageElement}
          currentAnswer={props.currentAnswer as ChatActivityAnswer}
          answer={props.answer}
        />
      );
      break;
    case 'resourcesPage':
      content = <ResourcesPage pageElement={props.pageElement} />;
      break;
    default:
      content = <></>;
  }
  return <>{content}</>;
};

// function MUISelect({ options, placeholder }) {
//   const [age, setAge] = useState("-1");
//   const classes = useStyles();

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };
//   const id = nanoid();
//   return (
//     <FormControl>
//       <Select
//         id={id}
//         value={age}
//         label={placeholder}
//         onChange={handleChange}
//         autoWidth
//         className={classes.select}
//       >
//         <MenuItem disabled value="-1">
//           <em>
//             <font color="#adadad">{placeholder.toLowerCase()}</font>
//           </em>
//         </MenuItem>
//         {options?.map((option, index) => (
//           <MenuItem key={index} value={option}>
//             {option.toLowerCase()}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// }

export default Others;
