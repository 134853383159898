import type { PageElement } from '@app/@types/redux/project';
import type { SlideOverData } from '@components/UI/Html/SlideOver';
import env from '@config/env';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import { useAppSelector } from '@state/redux/store';
import { type Dispatch, type SetStateAction } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface NotesProps {
  slideOverData: SlideOverData;
  setSlideOverData: Dispatch<SetStateAction<SlideOverData>>;
}
const Notes = (props: NotesProps) => {
  const { slideOverData, setSlideOverData } = props;
  const project = useAppSelector((state) => state.projects.currentProject);

  const filteredFeedbacks = project.project.attributes.projectStages.filter((stage) => {
    return stage.attributes.stageName.toLowerCase() === 'feedback';
  });
  const findFeedbackId = (data: PageElement[]) => {
    return data.find((item) => item.attributes.elementType === PAGE_ELEMENT.CARD_FEEDBACK);
  };

  return (
    <div>
      {slideOverData.width === 'l' ? (
        <div>
          {filteredFeedbacks.map((feedback) => {
            const rawId = findFeedbackId(feedback.attributes.pages[0].attributes.pageElements);
            if (!rawId) return null;
            const id = `feedback-${rawId.id}`;

            return (
              <>
                <div
                  key={feedback.id}
                  className="tf-container"
                  id={id}
                  onClick={() => {
                    setSlideOverData({
                      ...slideOverData,
                      isOpen: true,
                      header: feedback.attributes.parentStageName,
                      subHeader: 'Tedo Feedback',
                      width: '3xl',
                    });
                  }}
                >
                  <div className="tf-icon-container">
                    <i className={`ni ${feedback.attributes.icon} tf-icon-main`} />
                  </div>
                  <h2 className="tf-text">{feedback.attributes.parentStageName}</h2>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <Notepad name={filteredFeedbacks[0].attributes.parentStageName} />
      )}
    </div>
  );
};
export default Notes;

interface NotepadProps {
  name: string;
}
const Notepad = ({ name }: NotepadProps) => {
  return (
    <div className="feedback-container">
      <div className="feedback-tiles-container">
        <div className="feedback-tiles" style={{ height: '800px' }}>
          <h3>Tips to unpack</h3>
          <p className="scoring-rationale">{name.toUpperCase()}</p>
        </div>
      </div>
      <div className="tab-content" style={{ width: '100%' }}>
        <Editor
          apiKey={env.TINY_MCE_API_KEY}
          onBlur={() => {
            console.log('hello');
          }}
          initialValue={''}
          init={{
            placeholder: 'Eg. Share your thoughts here...',
            menubar: false,
            height: '800px',
            statusbar: false,
            plugins:
              'preview searchreplace autolink save directionality visualchars image link media codesample table anchor insertdatetime advlist lists wordcount charmap quickbars emoticons fullscreen',
            toolbar:
              'fullscreen undo redo | fontfamily font_size_formats formatselect fontsize bold italic underline strikethrough | image | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor permanentpen formatpainter removeformat | pagebreak | charmap emoticons | preview print | media link anchor codesample | a11ycheck ltr rtl',
            content_css: '/assets/styles/WYSIWYGEditor.css',
            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
            font_size_formats:
              '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
            body_class: 'WYSIWYGEditor',
            autosave_restore_when_empty: true,
            spellchecker_active: true,
            browser_spellcheck: true,
          }}
        />
      </div>
    </div>
  );
};
