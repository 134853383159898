import { Note } from '@app/@types/redux/users';
import { nanoid } from '@reduxjs/toolkit';
import { fetchMyNotes } from '@state/redux/slices/user.slice';
import { RootState, useAppDispatch } from '@state/redux/store';
import { translate } from '@state/utils/helper';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { i18nCText } from '../../../libs/i18n/I18n';
import { Button, Col, Container, Div, Row, TimeAgo } from '../../UI/Html';
import NoteSidePane from './NoteSidePane';

const Notes = () => {
  const [note, setNote] = useState<Note>({
    id: '',
    type: '',
    attributes: {
      id: '',
      title: '',
      text: '',
      updatedAt: '',
    },
  });
  const notes = useSelector((state: RootState) => state.users.notes.myNotes);
  const [loading, setLoading] = useState(true);
  const [noteSidePane, setNoteSidePane] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(fetchMyNotes())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);

  const toggleNoteSidePane = () => {
    setNoteSidePane(!noteSidePane);
  };

  const loadingSkeleton = () => (
    <>
      {Array.from(Array(3), () => (
        <Col xs={12} sm={6} lg={4} className="mb-5" key={nanoid()}>
          <Skeleton height={300} />
        </Col>
      ))}
    </>
  );

  return (
    <Container>
      <NoteSidePane isOpen={noteSidePane} onRequestClose={toggleNoteSidePane} note={note} />
      <Row className="my-5">
        <Col>
          <Row>
            <Col>
              <h2 className="text-uppercase font-weight-600 mt-1 mb-4">
                {translate('notes.myNotes')}
              </h2>
            </Col>
          </Row>
          <Row>
            {loading
              ? loadingSkeleton()
              : notes.map((item) => (
                  <Col className="note-container" md={4} key={item.id}>
                    <Div className="post-it-note">
                      <p>{i18nCText(item.attributes.title)}</p>
                      <Row>
                        <Col>
                          <TimeAgo date={item.attributes.updatedAt} />
                        </Col>
                        <Col className="text-right">
                          <Button
                            color="default"
                            type="button"
                            onClick={() => {
                              setNote(item);
                              toggleNoteSidePane();
                            }}
                          >
                            <i className="fas fa-pen" />
                          </Button>
                        </Col>
                      </Row>
                    </Div>
                  </Col>
                ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Notes;
